import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { Avatar, Button, Col, colors, LabeledRadio, Loading, Row, Text } from '@commonsku/styles';
import { LabeledSelect } from '../../helpers';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveUsersList } from '../../../selectors/users';
import {
  decrementActiveUsers,
  selectStripeCalculatedQuantity,
  selectStripePlanInterval,
  updateStripeQuantity,
} from '../../../redux/stripe';
import { createUpdateUserData } from '../../../actions';
import { getSocialFeatureId } from '../../../selectors';
import { useIdentity } from '../../../hooks';
import { getIdentityUtils } from '../../../utils';

export const DEACTIVATE_TYPE = {
  REMOVE_LICENSE: 'REMOVE_LICENSE',
  ADD_NEW_USER: 'ADD_NEW_USER',
  LEAVE_LICENSE_OPEN: 'LEAVE_LICENSE_OPEN',
};

const DeactivateUserForm = React.memo((props) => {
  const {
    user,
    isActive,
    reassignClientUserId,
    setReassignClientUserId,
    onUpdate,
    onClose,
    setDeactivated,
    showCreateUserForm,
    isDisabled = false,
  } = props;
  const dispatch = useDispatch();

  const socialFeatureId = useSelector(getSocialFeatureId);
  const activeUsers = useSelector(getActiveUsersList);
  const stripeQuantity = useSelector(selectStripeCalculatedQuantity);
  const stripeInterval = useSelector(selectStripePlanInterval);
  const identity = useIdentity();
  const { isTeam, isTenant, isSupplier, hasCapabilities } = getIdentityUtils(identity);
  const isFull = isTenant() && hasCapabilities('FEATURE-FULL');

  const [deactivateType, setDeactivateType] = useState(!isTeam() && isTenant() ? DEACTIVATE_TYPE.REMOVE_LICENSE : '');
  const [deactivating, setDeactivating] = useState(false);

  const userOptions = React.useMemo(() => {
    const result = activeUsers.map(v => {
      const userImage = _.get(v, ['user_image_paths', 'small']) || '/images/user-avatar3-120x120.png';
      return {
        value: v.user_id,
        label: <span>
          <Avatar pic={userImage} shape='circle' />
          <span style={{ paddingLeft: 10, verticalAlign: 'middle' }}>{v.contact_full_name}</span>
        </span>,
      };
    }).filter(v => v.value !== user.user_id);

    if (deactivateType !== DEACTIVATE_TYPE.ADD_NEW_USER) {
      return result;
    }
    return [{ label: 'New user', value: 'new_user' }].concat(result);
  }, [activeUsers, deactivateType, user]);
  const clientsCount = parseInt(user.clients_count || 0);
  const isProductionContact = ('1' === (''+user.production_contact));
  const isInvoicingContact = ('1' === (''+user.invoicing_contact));
  const prodInvText = useMemo(() => {
    if (isProductionContact && isInvoicingContact) {
      return 'a production or invoicing contact';
    } else if (isProductionContact) {
      return 'a production contact';
    } else if (isInvoicingContact) {
      return 'an invoicing contact';
    }
    return '';
  }, [isProductionContact, isInvoicingContact]);
  const canRemoveLicense = stripeQuantity > 2 && stripeInterval !== 'year';

  const doClose = async () => {
    if (deactivateType === DEACTIVATE_TYPE.ADD_NEW_USER) {
      setDeactivating(false);
      showCreateUserForm();
      return;
    } else if (isFull && deactivateType === DEACTIVATE_TYPE.REMOVE_LICENSE) {
      // Only FULL feature users can remove licenses
      if (stripeQuantity > 2) {
        await dispatch(updateStripeQuantity(stripeQuantity - 1));
        setDeactivating(false);
      } else {
        setDeactivating(false);
      }
    } else {
      setDeactivating(false);
    }
    setDeactivated(true);
    onClose && onClose();
  };

  const handleDeactivate = async () => {
    if (!user.user_id || !isActive || deactivating) { return; }

    setDeactivating(true);
    const data  = { active: 0, };
    if (isTenant() && !isTeam()) {
      data['feature_id'] = socialFeatureId;
    }
    if (!isSupplier() && reassignClientUserId !== 'new_user') {
      data['reassign_clients_to'] = reassignClientUserId;
    }
    const action = await dispatch(createUpdateUserData(user.user_id, data));
    if (action.payload.error) {
      setDeactivating(false);
      toast.error(action.payload.error);
      return;
    }
    onUpdate && onUpdate({ user_id: user.user_id, data });
    if (!isTeam() && isTenant()) {
      dispatch(decrementActiveUsers(1));
    }
    await doClose();
  };

  return (
    <Row id='deactivate-user-form'>
      {deactivating && <Col xs padded style={{ textAlign: 'center' }}>
        <Loading />
      </Col>}
      {!deactivating && <><Col padded xs style={{ paddingTop: 32, paddingBottom: 32, }}>
        <p id='deactivate-user-info-text'>
          You are about to deactivate {user.contact_first_name || ''}'s commonsku profile!
          Deactivating a user prevents them from logging into commonsku{isSupplier() ? '.' : ' and being assigned to new projects, shops, and clients.'}
          {!isFull &&
            <div style={{paddingTop: 35}}>The deactivated user's license will remain available on your commonsku account, and can be reassigned to another user. Licenses can be removed from your account at contract renewal using the "Renewal Management" settings.</div>
          }
        </p>
        {(isProductionContact || isInvoicingContact) ? <Text id='prod-inv-text'>
          This user will no longer be {prodInvText}
        </Text> : null}
      </Col>

      {isFull &&
        <>
          {(canRemoveLicense && !isTeam() && isTenant()) && <Col xs style={{ paddingBottom: 10, }}>
            <LabeledRadio
              id='deactivate-remove-license-radio'
              label='Deactivate and remove license'
              value={deactivateType === DEACTIVATE_TYPE.REMOVE_LICENSE}
              checked={deactivateType === DEACTIVATE_TYPE.REMOVE_LICENSE}
              onChange={() => setDeactivateType(DEACTIVATE_TYPE.REMOVE_LICENSE)}
              disabled={isDisabled}
            />
          </Col>}
          {!isTeam() && isTenant() && <Col xs>
            <LabeledRadio
              id='deactivate-open-license-radio'
              label='Deactivate and leave the license open'
              value={deactivateType === DEACTIVATE_TYPE.LEAVE_LICENSE_OPEN}
              checked={deactivateType === DEACTIVATE_TYPE.LEAVE_LICENSE_OPEN}
              onChange={() => setDeactivateType(DEACTIVATE_TYPE.LEAVE_LICENSE_OPEN)}
              disabled={isDisabled}
            />
          </Col>}
        </>
      }

      <Col style={clientsCount === 0 ? {display: 'none'} : {paddingTop: 10}}>
        <LabeledSelect
          id='reassign-client-user'
          inPopup
          isClearable
          isDisabled={isDisabled}
          name="reassignClientUserId"
          label={`Optional: Reassign ${(user.contact_first_name || 'user')}'s ${clientsCount} clients to`}
          value={reassignClientUserId}
          onChange={v => setReassignClientUserId(v ? v.value : '')}
          options={userOptions}
        />
      </Col>
      <Col xs pt={40}>
        <Button
          id='deactivate-user-btn'
          variant={(!isActive || deactivating) ? 'disabled' : 'error'}
          onClick={handleDeactivate}
          style={{ display: 'inline' }}
          disabled={deactivating}
        >{deactivating ? 'Deactivating...' : 'Deactivate'}</Button>
      </Col></>}
    </Row>
  );
});

export default DeactivateUserForm;
